.search-results-env {
	@border: #ebebeb;
	@bg: #f9f9f9;
	
	.search-string {
		font-size: @font-size-h4 + 2;
		
		strong {
			color: @secondary_color;
			font-weight: normal;
		}
	}
	
	.nav-tabs  {
		margin-bottom: 0;
		border-bottom-color: @border;
		
		li {
			
			a {
				background: @hover_bg;
				border-color: @border;
			}
			
			&.active {
				
				a {
					background: @bg;
					border-bottom-color: transparent;
				}
			}
		}
	}
	
	
	.search-bar {
		padding: @base_margin - @base_padding/2;
		background: @bg;
		margin-bottom: @base_margin;
		
		.form-control + .input-group-btn {
			border: 1px solid @main_color;
			border-left: 0;
			padding-left: @border-radius-base;
			background-color: @main-color;
			.border-radius(0 @border-radius-base @border-radius-base 0);
			//border: 1px solid @main_color;
			
			.button {
				.border-radius(0);
			}
		}
	}
	
	
	
	.search-results-panes {
		position: relative;
		
		.search-results-pane {
			position: relative;
			display: none;
			
			&.active {
				display: block;
			}
		}
	}
	
	
	.search-results {
		list-style: none;
		margin: 0;
		padding: 0;
		
		> .search-result {
			border-bottom: 1px solid lighten(@border, 3%);
			padding-bottom: @base_margin - @base_padding;
			margin: 0;
			margin-bottom: @base_margin - @base_padding;
			
			h4 {
				font-size: @font-size-h4 + 3;
				margin: 0;
				margin-bottom: @base_padding;
			}
			
			.link {
				color: @secondary_color;
				
				&:hover {
					text-decoration: underline;
				}
			}
			
			&:last-child {
				padding-bottom: 0;
				border-bottom: 0;
			}
			
			.sr-inner {
				padding: @base_margin / 2;
				.transall;
				
				&:hover {
					background: @bg;
				}
			}
		}
	}
	
	.search-results-messages {
		
		thead, tbody {
			tr {
				th, td {
					padding: @base_padding @base_padding;
				}
			}
		}
		
		tbody {
			
			tr {
				background: @bg;
				
				&.unread {
					background: #FFF;
					
					> td:nth-child(2) {
						
						a {
							font-weight: bold;
						}
					}
				}
						
				.star {
					display: inline-block;
					color: #dddddd;
					margin-right: 5px;
					font-size: 14px;
					.transall;
					
					&.stared,
					&.starred {
						color: #ff9600;
					}
				}
			}
		}
	}
}


@media (max-width: 570px) {
	
	.search-results-env {
	
		.nav-tabs {
			text-align: left;
			
			> li {
				float: none !important;
				
				&.pull-left {
					display: block;
					width: 100%;
				}
				
				.search-string {
					padding-bottom: @base_margin / 2;
				}
			}
		}
	}
}
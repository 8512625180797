.wysihtml5-toolbar {
	.clearfix;
	
	li {
		margin-right: 8px !important;
		
		&.pull-right {
			margin-right: 0 !important;
			margin-left: 8px !important;
		}
	}
}	
// Responsiveness

// Middle Screen
@media (max-width: @screen-md-max) {
	
	// Pull none (minimal)
	.pull-none-md {
		float: none !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Left (minimal)
	.pull-left-md {
		float: left !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Right (minimal)
	.pull-right-md {
		float: right !important;
		
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}
	
}

// Tablets
@media (max-width: @screen-sm-max) {
	
	// Pull none (minimal)
	.pull-none-sm {
		float: none !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Left (minimal)
	.pull-left-sm {
		float: left !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Right (minimal)
	.pull-right-sm {
		float: right !important;
		
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}
}


// Small Screens
@media (max-width: @screen-xs-max) {
	
	// Pull none (minimal)
	.pull-none-xs {
		float: none !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Left (minimal)
	.pull-left-xs {
		float: left !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Right (minimal)
	.pull-right-xs {
		float: right !important;
		
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}
}


// Very Small Screens
@screen-xsm-min: @screen-xs-min - 140;

@media (max-width: @screen-xsm-min) {
	
	// Pull none (minimal)
	.pull-none-xsm {
		float: none !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Left (minimal)
	.pull-left-xsm {
		float: left !important;
		
		.dropdown-menu {
			left: 0;
			right: auto;
		}
	}
	
	// Pull Right (minimal)
	.pull-right-xsm {
		float: right !important;
		
		.dropdown-menu {
			left: auto;
			right: 0;
		}
	}
}
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.border-top-left-radius(@radius) {
  -webkit-border-top-left-radius: @radius;
  -moz-border-radius-topleft: @radius;
  border-top-left-radius: @radius;
}

.border-bottom-left-radius(@radius) {
  -webkit-border-bottom-left-radius: @radius;
  -moz-border-radius-bottomleft: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  .border-top-left-radius(@radius);
  .border-bottom-left-radius(@radius);
}

// Transitions
.transition(@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

// Box sizing
.box-sizing(@boxmodel) {
  -webkit-box-sizing: @boxmodel;
  -moz-box-sizing: @boxmodel;
  box-sizing: @boxmodel;
}

// User select
// For selecting text on the page
.user-select(@select) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  -ms-user-select: @select;
  -o-user-select: @select;
  user-select: @select;
}

// Opacity
/*.opacity(@opacity) {
  opacity: @opacity / 100;
  filter: ~"alpha(opacity=@{opacity})";
}*/

// Gradient Bar Colors for buttons and alerts
.gradientBar(@primaryColor, @secondaryColor, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,.25)) {
  color: @textColor;
  //text-shadow: @textShadow;
  #gradient > .vertical(@primaryColor, @secondaryColor);
  background-color: @primaryColor;
  border-color: @secondaryColor @secondaryColor darken(@secondaryColor, 15%);
  border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) fadein(rgba(0, 0, 0, .1), 15%);
}

// Gradients
#gradient {
  .vertical(@startColor: #555, @endColor: #333) {
    background-color: mix(@startColor, @endColor, 60%);
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor)); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(%("progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",argb(@startColor), argb(@endColor))); // IE9 and down
  }
}

.reset-filter() {
  filter: e(%("progid:DXImageTransform.Microsoft.gradient(enabled = false)"));
}

.buttonBackground(@startColor, @endColor, @textColor: #fff, @textShadow: 0 -1px 0 rgba(0,0,0,.25)) { // gradientBar will set the background to a pleasing blend of these, to support IE<=9
  //.gradientBar(@startColor, @endColor, @textColor, @textShadow);
  *background-color: @endColor; /* Darken IE7 buttons by default so they stand out more given they won't have borders */
  .reset-filter(); // in these cases the gradient won't cover the background, so we override
  
  &.switch-left {
	  background-color: @endColor;
	  color: @textColor;
  }
  
  &.switch-right {
	  background-color: @hover_bg;
	  color: @text_color_light;
	  
	  &:hover {
	  	  background-color: @hover_bg;//lighten(@hover_bg, 5%);//fade(@endColor, 40%);
		  color: @text_color_light;
	  }
  }
  
  &:hover, &:focus, &:active, &.active, &.disabled, &[disabled] {
    color: @textColor;
    //background-color: @endColor;
    *background-color: darken(@endColor, 5%);
  }

// IE 7 + 8 can't handle box-shadow to show active, so we darken a bit ourselves
  &:active,
  &.active {
    background-color: darken(@endColor, 10%) e("\9");
  }
}

/* ============================================================
 * bootstrapSwitch v1.8 by Larentis Mattia @SpiritualGuru
 * http://www.larentis.eu/
 *
 * Enhanced for radiobuttons by Stein, Peter @BdMdesigN
 * http://www.bdmdesign.org/
 *
 * Project site:
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */

@import "bs-switch-deps/variables";
@import "bs-switch-deps/mixins";

@s_radius: 50px;

.has-switch {
  display: inline-block;
  cursor: pointer;
  .border-radius(@s_radius);
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
  overflow: hidden;
  line-height: 8px;
  .user-select(none);
  vertical-align: middle;

  min-width: 100px;

  &.switch-mini, 
  &.switch-xs {
    min-width: 72px;
  }
  &.switch-mini i.switch-mini-icons, 
  &.switch-xs i.switch-mini-icons {
    height: 1.20em;
    line-height: 9px;
    vertical-align: text-top;
    text-align: center;
    transform: scale(0.6);
    margin-top: -1px;
    margin-bottom: -1px;
  }
  &.switch-small,
  &.switch-sm {
    min-width: 80px;
  }

  &.switch-large,
  &.switch-lg {
    min-width: 120px;
  }

  &.deactivate {
    .opacity(50);
    cursor: default !important;
    label, span {
      cursor: default !important;
    }
  }
  > div {
    display: inline-block;
    width: 150%;
    position: relative;
    top: 0;

    &.switch-animate {
      .transition(left 0.5s);
    }
    &.switch-off {
      left: -50%;
    }
    &.switch-on {
      left: 0%;
    }
  }
  input[type=radio],
  input[type=checkbox] {
    //debug
    display: block;
    position: absolute;
    margin-left: -60%;
    //z-index: 123;
  }

  span, label {
    .box-sizing(border-box);

    cursor: pointer;
    position: relative;
    display: inline-block;
    height: 100%;

    padding-bottom: 4px;
    padding-top: 4px;
    font-size: 14px;
    line-height: 20px;

    &.switch-mini, 
    &.switch-xs {
      padding-bottom: 4px;
      padding-top: 4px;
      font-size: 10px;
      line-height: 9px;
    }

    &.switch-small,
    &.switch-sm {
      padding-bottom: 3px;
      padding-top: 3px;
      font-size: 12px;
      line-height: 18px;
    }

    &.switch-large, 
    &.switch-lg {
      padding-bottom: 9px;
      padding-top: 9px;
      font-size: 16px;
      line-height: normal;
    }
  }

  label {
    text-align: center;
    margin-top: -1px;
    margin-bottom: -1px;
    z-index: 100;
    width: 34%;
    border-left: 1px solid @btnBorder;
    border-right: 1px solid @btnBorder;

    .buttonBackground(@btnBackground, @btnBackgroundHighlight, @grayDark);

    i {
      color: #000;
      //text-shadow: 0 1px 0 #fff;
      line-height: 18px;
      pointer-events: none;
    }
  }

  span {
    text-align: center;
    z-index: 1;
    width: 33%;

    &.switch-left {
      .border-left-radius(@s_radius - 1);
    }

    &.switch-right {
      .buttonBackground(@btnBackgroundHighlight, @btnBackground, @grayDark, 0 1px 1px rgba(255,255,255,.75));
    }

    &.switch-primary, &.switch-left {
      .buttonBackground(@btnPrimaryBackgroundHighlight, @btnPrimaryBackground);
    }

    &.switch-info {
      .buttonBackground(@btnInfoBackgroundHighlight, @btnInfoBackground);
    }

    &.switch-success {
      .buttonBackground(@btnSuccessBackgroundHighlight, @btnSuccessBackground);
    }

    &.switch-warning {
      .buttonBackground(@btnWarningBackgroundHighlight, @btnWarningBackground);
    }

    &.switch-danger {
      .buttonBackground(@btnDangerBackgroundHighlight, @btnDangerBackground);
    }

    &.switch-default {
      .buttonBackground(@btnBackgroundHighlight, @btnBackground, @grayDark, 0 1px 1px rgba(255,255,255,.75));
    }
  }
}

.page-error-404 {
	color: @main_color;
	text-align: center;
	
	.error-symbol {
		font-size: 120px;
	}
	
	.error-text {
		padding-bottom: 25px;
		font-size: 16px;
		
		h2 {
			font-size: 45px;
		}
		
		p {
			font-size: 22px;
		}
		
		+ hr {
			margin-bottom: 50px;
		}
	}
	
	.input-group {
		width: 250px;
		margin: 0 auto;
	}
}


// The dropdown menu (ul)
.dropdown-menu {
  .box-shadow(none);
  
  &.dropdown-green { .dropdown-variant(@brand-success; darken(@brand-success, 4%); #fff); }
  &.dropdown-gold { .dropdown-variant(#fcd036; darken(#fcd036, 13%); #846e20; darken(#fcd036, 12%)); }
  &.dropdown-blue { .dropdown-variant(#0072bc; darken(#0072bc, 5%); #fff; darken(#0072bc, 5%)); }
  &.dropdown-primary { .dropdown-variant(#303641; @main_border_color; #fff; darken(#303641, 5%)); }
  &.dropdown-red { .dropdown-variant(#d42020; darken(#d42020, 7%); #fff; darken(#d42020, 5%)); }
  &.dropdown-black { .dropdown-variant(#000; lighten(#000, 27%); #fff; lighten(#000, 22%)); }
}

.dropdown-variant(@bg-color; @border-color; @text-color)
{
	@hover-bg: darken(@bg-color, 5%);
	.dropdown-variant(@bg-color; @border-color; @text-color; @hover-bg);
}

.dropdown-variant(@bg-color; @border-color; @text-color; @hover-bg)
{
	background-color: @bg-color;
	border-color: @border-color;
	  
	> li {
	  
	  > a {
		  color: @text-color;
	  }
	  
	  &:hover a,
	  &:active a {
		  background-color: @hover-bg;
		  color: @text-color;
	  }
	}
	
	.divider, .divider:hover {
	  .nav-divider(@border-color);
	}
}
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: block;
  padding: @padding-base-vertical @padding-base-horizontal - 4;
  padding-bottom: @padding-base-vertical + 2;
  margin-bottom: 10px;
  color: #555;
  vertical-align: middle;
  max-width: 100%;
  line-height: 22px;
  border: 1px solid @default;
  .rounded;
  
  &:hover {
	  border-color: #cbd0d9;
  }

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: auto !important;
    max-width: inherit;

    &:focus {
      border: none;
      box-shadow: none;
      
    }
  }

  .tag {
  	display: inline-block;
    margin-right: 2px;
    color: white;
    font-size: 10px;

    [data-role="remove"] {
      margin-left:8px;
      cursor:pointer;
      &:after{
        content: "x";
        padding:0px 2px;
      }
      &:hover {
        //box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
        &:active {
          //box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
        }
      }
    }
  }
}
// Font Family
@font_family:								"Helvetica Neue", Helvetica, "Noto Sans", sans-serif;
@font_entypo: 								"Entypo";


@sidebar_menu_width: 						280px;
@sidebar_collapsed_width: 					65px;
@sidebar_collapsed_hover_width:				250px;

@horizontal_submenu_width: 					250px;
@horizontal_notifications_width: 			370px;
@horizontal_notifications_scroller: 		290px;

@chat_width: 								280px;
@chat_converation_width: 					340px;


@hover_bg: #f5f5f6;

@font_weight: 								400;
@font_weight_bold: 							400;

	
// Fonts
@p_size: 									12px;

@small_font_size: 							11px;
@medium_font_size: 							14px; 


// Distancers
@base_padding: 								10px;
@base_margin: 								30px;
@border_radius: 							3px;

@inner_padding: 							20px;


// Theme Colors
@text_color_light: 							#737881;
@text_disabled: 							#dee0e4;

@main_color: 								#303641;

@main_text_color: 							#aaabae; // or fade(#FFF, 50%)
@main_text_acitve_color: 					#fff;
@main_border_color: 						#454a54;
@main_active_color: 						#262b34;

@main_color_inv: 							#de6c65;

@main_color_2: 								#7f8186;


@hover_text_color: 							lighten(@main_color, 35%);

@menu_active_color: 						#2b303a;
@menu_active_text_color: 					#fff;
@submenu_color: 							#2b303a;
@submenu_color_2: 							#272b34;

@background_color: 							#ffffff;

@secondary_background_color: 				#f0f0f1;
@secondary_color: 							#ec5956;
@secondary_text_color: 						#fff;


// Combination of Colors (badges, buttons, tabs, etc)
@default: 									#ebebeb;
@default_text:								#373e4a;
@p_text_color: 								#949494;

@primary: 									@main_color;
@primary_text: 								#fff;

@secondary: 								#ee4749;
@secondary_text: 							#fff;

@success: 									#00a651;
@success_text: 								#fff;

@info: 										#21a9e1;
@info_text: 								#fff;

@warning: 									#fad839;
@warning_text:								#fff;

@danger: 									#cc2424;
@danger_text: 								#fff;